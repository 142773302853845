import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

function FAQ() {
  const [openQuestion, setOpenQuestion] = useState(null);

  const faqItems = [
    { question: "Do you have specific pricing plans to show?", answer: "Yes, we have various pricing plans tailored to fit different needs." },
    {
      question: "How many years of experience do you have?",
      answer: "We have over 10 years of experience in the industry, ensuring high-quality service.",
    },
    { question: "What companies have you worked with?", answer: "We've collaborated with several notable companies, enhancing their design capabilities." },
    { question: "Am I safe leaving my company in your hands?", answer: "Absolutely! We prioritize security and confidentiality in all our projects." },
  ];

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <section className="w-full bg-white py-20">
      <div className="flex flex-col items-center w-full max-w-[1209px] mx-auto px-20 max-md:px-5">
        <h2 className="text-5xl font-bold tracking-tighter leading-none text-center text-stone-950 max-md:text-4xl">
          FAQ's
        </h2>
        <p className="mt-3 text-lg leading-snug text-center text-black text-opacity-60">
          Providing answers to your questions
        </p>
        {faqItems.map((item, index) => (
          <motion.div
            key={index}
            className={`flex relative flex-col flex-wrap gap-2 px-5 py-4 mt-8 w-[90%] max-w-[500px] text-lg font-medium leading-loose text-white rounded-[12px] shadow-md transition-colors duration-200 ${
              openQuestion === index ? "bg-red-600" : "bg-stone-700"
            }`}
          >
            <div className="relative flex-auto my-auto">
              <span className="font-semibold">{item.question}</span>
            </div>
            <button
              onClick={() => toggleQuestion(index)}
              className={`flex relative shrink-0 h-[45px] w-[45px] items-center justify-center rounded-full transition-transform duration-300 ${
                openQuestion === index ? "bg-red-700 rotate-45" : "bg-red-600"
              }`}
              aria-expanded={openQuestion === index}
              aria-controls={`faq-answer-${index}`}
            >
              {openQuestion === index ? "-" : "+"}
            </button>
            <AnimatePresence>
              {openQuestion === index && item.answer && (
                <motion.div
                  id={`faq-answer-${index}`}
                  className="relative mt-3 text-base leading-8 text-stone-300"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {item.answer}
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
        <form className="mt-9 w-full max-w-[541px]">
          <label htmlFor="faq-question" className="sr-only">
            Ask us what you want to know...
          </label>
          <textarea
            id="faq-question"
            className="px-5 pt-5 pb-20 mt-9 max-w-full text-base tracking-normal leading-loose text-black rounded-2xl border-2 border-solid border-stone-950 w-full"
            placeholder="Ask us what you want to know..."
          ></textarea>
          <div className="flex flex-wrap gap-5 justify-between mt-7 max-w-full w-[536px]">
            <p className="text-sm tracking-normal leading-6 text-zinc-900 text-opacity-60">
              We will answer your questions via email within 48 hours.
            </p>
            <button
              type="submit"
              className="self-start px-14 py-3.5 text-base font-semibold tracking-tight text-center whitespace-nowrap bg-red-600 rounded-[30px] text-stone-950 max-md:px-5"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default FAQ;
