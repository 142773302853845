import React from "react";

function Navigation() {
  const navItems = ["Process", "Benefits", "Services", "Portfolio", "FAQ"];

  return (
    <nav className="flex self-start text-base max-md:max-w-full overflow-x-auto">
      <div className="flex flex-row md:gap-8 gap-4 justify-start items-center my-auto leading-loose whitespace-nowrap">
        {navItems.map((item, index) => (
          <a
            key={index}
            href={`#${item.toLowerCase()}`}
            className="self-stretch my-auto hover:text-red-500 transition-colors duration-200"
          >
            {item}
          </a>
        ))}
      </div>
    </nav>
  );
}

export default Navigation;