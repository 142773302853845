import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

function TrustedBrands() {
  const [inView, setInView] = useState(false);
  const brandsRef = useRef(null);

  const brands = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/a7c309a6e1b7ea15508ea46ed60a49353f199f42ea70f42269b3aef2d1c2c5c0?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Brand 1",
      width: "146px",
      aspect: "3.04",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/1577f02292fbb996bd9e4668c296877d01a11bb46ea8637786044ca220092b68?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Brand 2",
      width: "187px",
      aspect: "3.89",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4606fa9d04fd26f4f9bbecbc05cd796b70f1ff04f4de0ecc32709dfee3ade977?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Brand 3",
      width: "183px",
      aspect: "3.82",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/47014ee1d54eaa95c269b7d0fad7760fbaf091d2d28c9a9aa6d8b40af59b5e79?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Brand 4",
      width: "194px",
      aspect: "4.05",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/57666a9daea1e4e09dd3982a58281f97602c2202d9b432ef206cc3aa2aa3a5ec?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Brand 5",
      width: "216px",
      aspect: "4.5",
    },
  ];

  const brandVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        } else {
          setInView(false);
        }
      },
      { threshold: 0.1 } // Trigger when 10% is visible
    );

    const currentRef = brandsRef.current; // Store the current ref in a variable
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the stored variable in the cleanup
      }
    };
  }, []);

  return (
    <section className="flex flex-col items-center mt-4 text-white">
      <div className="flex gap-10 items-center mt-16 text-base font-bold tracking-widest leading-relaxed text-white uppercase max-w-full whitespace-nowrap">
        <div className="flex-shrink-0 self-stretch my-auto h-0.5 bg-white w-[50px]" />
        <div className="flex-grow text-center">TRUSTED BY AMAZING BRANDS</div>
        <div className="flex-shrink-0 self-stretch my-auto h-0.5 bg-white w-[50px]" />
      </div>
      <div className="flex flex-col self-stretch px-5 md:px-20 mt-9 w-full">
        <div className="flex flex-col justify-center px-5 py-4 w-full rounded-3xl bg-orange-950 max-md:px-5 max-md:max-w-full" ref={brandsRef}>
          <div className="flex flex-wrap gap-10 justify-center items-center">
            {brands.map((brand, index) => (
              <motion.img
                key={index}
                loading="lazy"
                src={brand.src}
                alt={brand.alt}
                variants={brandVariants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"} // Trigger animation based on inView state
                transition={{ duration: 0.5, delay: index * 0.1 }} // Staggered effect
                className={`object-contain shrink-0 self-stretch my-auto aspect-[${brand.aspect}] w-[${brand.width}] max-h-16`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default TrustedBrands;
