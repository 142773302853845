import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import TrustedBrands from "./TrustedBrands";
import HowWeWork from "./HowWeWork";
import Portfolio from "./Portfolio";
import Benefits from "./Benefits";
import Testimonial from "./Testimonial";
import FAQ from "./FAQ";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";

function MainPage() {
  return (
    <div className="flex flex-col items-center pt-4 pb-20 bg-black max-md:pb-16 max-md:px-4 overflow-x-hidden">
      <Header />
      <Hero className="mb-8"/>
      <TrustedBrands className="mb-8"/>
      <div id="process" className="w-full max-w-screen-xl">
        <HowWeWork />
      </div>
      <div id="portfolio" className="w-full">
        <Portfolio />
      </div>
      <div id="benefits" className="w-full max-w-screen-xl">
        <Benefits />
      </div>
      <Testimonial className="w-full max-w-screen-xl"/>
      {/* <div id="services" className="w-full max-w-screen-xl">
        <Pricing />
      </div> */}
      {/* <Blog className="w-full max-w-screen-xl" /> */}
      <div id="faq" className="w-full">
        <FAQ />
      </div>
      <Footer className="w-full max-w-screen-xl px-4 md:px-10 lg:px-20" />
      <ScrollToTopButton/>
    </div>
  );
}

export default MainPage;
