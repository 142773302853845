import React from "react";
import { motion } from "framer-motion"; // Import motion for animations
import Button from "./Button";

function Hero() {
  // Animation variants for text and button
  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, delay: 0.2 },
    },
  };

  const buttonHover = {
    scale: 1.1,
    transition: {
      duration: 0.3,
    },
  };

  return (
    <section className="relative flex flex-col items-center pt-20 text-white overflow-hidden ">
      {/* Blurry Background */}
      <div className="absolute inset-0 flex items-center justify-center ">
        <div className="bg-red-500 opacity-35 blur-3xl rounded-full h-[380px] w-[450px] max-md:h-[300px] max-md:w-[300px]"></div>
      </div>

      {/* Animated Title */}
      <motion.h1
        className="relative z-10 text-6xl md:text-8xl font-bold tracking-tighter text-center leading-tight w-full max-w-[983px] mt-10 px-4 max-md:text-5xl"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.5 }}
        variants={textVariants}
      >
        Unleash <br /> Your{" "}
        <span className="italic font-extrabold text-red-500">Imagination</span>
      </motion.h1>

      {/* Animated Description (Desktop) */}
      <motion.p
        className="relative z-10 mt-6 text-lg leading-6 text-center text-white text-opacity-80 md:block hidden"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.5 }}
        variants={textVariants}
        transition={{ delay: 0.3 }}
      >
        Experience the magic of Design and animation with Motion Hill Studio. <br />
        Bring your ideas to life and captivate your audience.
      </motion.p>

      {/* Animated Description (Mobile) */}
      <motion.p
        className="relative z-10 mt-6 text-base leading-relaxed text-center text-white text-opacity-80 px-6 md:hidden block"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.5 }}
        variants={textVariants}
        transition={{ delay: 0.3 }}
      >
        Experience the magic of Design <br />
        and animation with Motion Hill Studio. <br />
        Bring your ideas to life and captivate <br />
        your audience.
      </motion.p>

      {/* Animated Button */}
      <motion.div
        className="relative z-10 mt-14 max-md:mt-10"
        whileHover={buttonHover}
      >
        <Button
          text="Book A Meeting"
          className="gap-3.5 px-3.5 py-4 max-w-full text-base font-medium leading-none text-white bg-red-600 rounded-lg min-h-[56px] w-[149px] mx-auto"
        />
      </motion.div>
    </section>
  );
}

export default Hero;