import React, { useState, useEffect, useRef } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { motion } from "framer-motion"; // Import motion for animations

function Testimonial() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState('right');
  const [isVisible, setIsVisible] = useState(false); 
  const sectionRef = useRef(null); 

  const testimonials = [
    {
      rating: "https://cdn.builder.io/api/v1/image/assets/TEMP/bc3b53f3f82760b983c73aeff283fcd4f52793887c54d310406a7b0351d7ef15?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      title: "MHS is the best digital agency I have ever seen! Highly Recommended!",
      content: "I recently hired Ideapeel for a custom web development project and couldn't be happier with the results. The team was able to bring my unique ideas to life and create a website that truly stands out.",
      name: "Diana Loreza",
      position: "Director of GYMSTORY",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/7ba92852c4b8a09db5d10ecd66b3155e5351366f8da86d4f04d6bd4c2411ce17?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
    },
    {
      rating: "https://cdn.builder.io/api/v1/image/assets/TEMP/bc3b53f3f82760b983c73aeff283fcd4f52793887c54d310406a7b0351d7ef15?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      title: "Pixel is the best digital agency I have ever seen! Highly Recommended!",
      content: "I recently hired Ideapeel for a custom web development project and couldn't be happier with the results. The team was able to bring my unique ideas to life and create a website that truly stands out.",
      name: "Diana Loreza",
      position: "Director of GYMSTORY",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/7ba92852c4b8a09db5d10ecd66b3155e5351366f8da86d4f04d6bd4c2411ce17?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
    },
    {
      rating: "https://cdn.builder.io/api/v1/image/assets/TEMP/bc3b53f3f82760b983c73aeff283fcd4f52793887c54d310406a7b0351d7ef15?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      title: "NPCI is the best digital agency I have ever seen! Highly Recommended!",
      content: "I recently hired Ideapeel for a custom web development project and couldn't be happier with the results. The team was able to bring my unique ideas to life and create a website that truly stands out.",
      name: "Diana Loreza",
      position: "Director of GYMSTORY",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/7ba92852c4b8a09db5d10ecd66b3155e5351366f8da86d4f04d6bd4c2411ce17?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
    }
  ];

  const handleTransition = (newIndex, newDirection) => {
    setIsAnimating(true);
    setDirection(newDirection);
    
    setTimeout(() => {
      setCurrentIndex(newIndex);
      setIsAnimating(false);
    }, 300);
  };

  const handlePrevious = () => {
    const newIndex = currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
    handleTransition(newIndex, 'left');
  };

  const handleNext = () => {
    const newIndex = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
    handleTransition(newIndex, 'right');
  };

  // Define animation variants for text
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.8 } // Slower duration for visibility transition
    },
  };

  // Set up Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting); // Update visibility state
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    // Store current ref in a variable
    const currentSectionRef = sectionRef.current;

    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }

    // Cleanup on unmount
    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="flex flex-col items-center self-stretch px-20 py-36 mt-24 w-full bg-white max-md:px-5 max-md:py-24 max-md:mt-10 max-md:max-w-full">
      <motion.h2 
        className="text-base leading-snug text-center uppercase text-stone-950"
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={textVariants}
      >
        testimonial
      </motion.h2>
      <motion.h3 
        className="mt-1.5 text-5xl font-bold tracking-tighter text-center leading-[58px] text-stone-950 max-w-full max-md:text-4xl max-md:leading-[54px]"
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={textVariants}
      >
        Customer is Our Top Priority
      </motion.h3>
      <motion.p 
        className="mt-7 text-lg leading-6 text-center text-stone-950 max-w-full"
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={textVariants}
      >
        We survey all of our clients, the results of which go directly to our CEO.
      </motion.p>

      <div className="relative flex items-center justify-center w-full mt-16 max-md:mt-10">
        <button 
          onClick={handlePrevious}
          className="absolute left-0 z-10 p-2 text-gray-600 transition-colors bg-white rounded-full shadow-lg hover:text-gray-900 hover:scale-110 active:scale-95 transition-transform duration-200"
        >
          <ChevronLeft size={24} />
        </button>

        <div className="flex flex-wrap items-start w-full max-w-5xl overflow-hidden">
          <motion.div 
            className={`flex flex-col flex-auto justify-center px-16 py-16 border border-solid bg-stone-50 border-zinc-300 rounded-[40px] shadow-[0px_6px_12px_rgba(0,0,0,0.05)] max-md:px-5 max-md:max-w-full transform transition-all duration-300 ease-in-out
              ${isAnimating ? 
                `opacity-0 ${direction === 'right' ? 'translate-x-10' : '-translate-x-10'}` : 
                'opacity-100 translate-x-0'
              }`}
          >
            <div className="flex gap-5 items-center max-md:flex-col">
              <div className="flex flex-col flex-1 max-md:ml-0">
                <div className="flex flex-col grow items-start font-bold max-md:mt-10 max-md:max-w-full">
                  <img
                    loading="lazy"
                    src={testimonials[currentIndex].rating}
                    alt="Five star rating"
                    className="object-contain max-w-full aspect-[4.98] w-[159px]"
                  />
                  <motion.h4 
                    className={`self-stretch mt-11 text-2xl leading-8 capitalize text-slate-900 max-md:mt-10 max-md:max-w-full`}
                    initial="hidden"
                    animate={isVisible ? "visible" : "hidden"}
                    variants={textVariants}
                  >
                    {testimonials[currentIndex].title}
                  </motion.h4>
                  <motion.p 
                    className="self-stretch mt-4 text-lg leading-7 text-stone-950 text-opacity-80 max-md:mr-2.5 max-md:max-w-full"
                    initial="hidden"
                    animate={isVisible ? "visible" : "hidden"}
                    variants={textVariants}
                  >
                    {testimonials[currentIndex].content}
                  </motion.p>
                  <p className="mt-9 text-xl leading-none text-stone-950">
                    {testimonials[currentIndex].name}
                  </p>
                  <p className="mt-1.5 text-base leading-relaxed text-stone-950 text-opacity-80">
                    {testimonials[currentIndex].position}
                  </p>
                </div>
              </div>
              <div className="flex-shrink-0 w-64 max-md:w-full transform transition-transform duration-300">
                <img
                  loading="lazy"
                  src={testimonials[currentIndex].image}
                  alt="Customer testimonial"
                  className={`object-cover w-full h-64 rounded-full transform transition-all duration-300 ease-in-out
                    ${isAnimating ? 'scale-95 opacity-0' : 'scale-100 opacity-100'}`}
                />
              </div>
            </div>
          </motion.div>
        </div>

        <button 
          onClick={handleNext}
          className="absolute right-0 z-10 p-2 text-gray-600 transition-colors bg-white rounded-full shadow-lg hover:text-gray-900 hover:scale-110 active:scale-95 transition-transform duration-200"
        >
          <ChevronRight size={24} />
        </button>
      </div>
    </section>
  );
}

export default Testimonial;
