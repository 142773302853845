import React, { useState } from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import Button from './Button';
import { Menu, X } from 'lucide-react';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

 //  console.log(process.env.REACT_APP_SERVICE)

  return (
    <header className="w-full bg-black text-white">
      <div className="max-w-[1050px] mx-auto px-6 py-4">
        {/* Desktop View */}
        <div className="flex items-center justify-between h-full"> {/* Ensure full height for centering */}
          <div className="md:scale-100 scale-75 transform origin-left flex items-center h-full"> {/* Flex for Logo */}
            <Logo />
          </div>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-5 ml-auto h-full"> {/* Add h-full for centering */}
            <div>
            <Navigation />

            </div>

            <Button
              text="Get Started"
              className="gap-2 pl-6 pr-4 py-2 font-medium leading-none bg-red-600 rounded-lg min-h-[56px]"
            />

            
          </div>
         


          {/* Mobile Hamburger Button */}
          <button
            onClick={toggleMenu}
            className="md:hidden p-2 hover:bg-gray-800 rounded-lg transition-colors"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="w-8 h-8" />
            ) : (
              <Menu className="w-8 h-8" />
            )}
          </button>
        </div>

        {/* Mobile Navigation */}
        <div
          className={`md:hidden ${
            isMenuOpen
              ? 'max-h-[500px] opacity-100 visible'
              : 'max-h-0 opacity-0 invisible'
          } transition-all duration-300 ease-in-out overflow-hidden`}
        >
          <div className="flex flex-col gap-6 pt-4 pb-6">
            <Navigation className="flex flex-col gap-4" />
            <Button
              text="Get Started"
              className="gap-2 px-6 py-2 font-medium leading-none bg-red-600 rounded-lg min-h-[56px] w-full"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
