import React, { useState, useEffect } from "react";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Show or hide the button based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <button
        onClick={scrollToTop}
        className="fixed bottom-8 right-8 w-12 h-12 rounded-full bg-red-500 text-white flex items-center justify-center transition-opacity duration-300 opacity-70 hover:opacity-100
                   max-md:bottom-4 max-md:right-4 max-md:w-10 max-md:h-10 z-50" // Added z-50 for higher z-index
      >
        <span className="material-icons">arrow_upward</span>
      </button>
    )
  );
}

export default ScrollToTopButton;
