import React from "react";
import { motion } from "framer-motion"; 
import Button from "./Button";

function HowWeWork() {
  // Define animation variants
  const titleVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5 } 
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5, delay: 0.2 } 
    },
  };

  const buttonHover = {
    scale: 1.05,
    transition: {
      duration: 0.3,
    },
  };

  return (
    <section className="mt-32 max-md:mt-10 max-md:max-w-full">
      <div className="ml-16 max-md:ml-2.5"> {/* Shift the whole section left */}
        <motion.h2
          className="text-left text-base font-semibold leading-snug text-red-600 uppercase"
          initial="hidden"
          whileInView="visible"
          variants={titleVariants}
          viewport={{ once: false, amount: 0.2 }} // Trigger animation when in view
        >
          How We Work
        </motion.h2>
        <div className="mt-4 w-full max-w-[1156px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <motion.div
              className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
              initial="hidden"
              whileInView="visible"
              variants={titleVariants}
              viewport={{ once: false, amount: 0.2 }}
            >
              <h3 className="text-6xl font-semibold tracking-tighter text-white leading-[58px] max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-10">
                Get a dedicated design team at a fraction of the cost.
              </h3>
            </motion.div>
            <motion.div
              className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full"
              initial="hidden"
              whileInView="visible"
              variants={textVariants}
              viewport={{ once: false, amount: 0.2 }}
            >
              <p className="self-stretch my-auto text-2xl font-light leading-9 text-white text-opacity-80 max-md:mt-10 max-md:max-w-full">
                Grow your brand with high-quality design for a flat monthly fee.
                Work with senior designers. Subscribe and make as many requests as
                you need - no limits.
              </p>
              <motion.div whileHover={buttonHover}>
                <Button
                  text="See Pricing"
                  className="gap-3.5 self-start px-3.5 py-4 mt-3 ml-0 max-w-full text-base font-medium leading-none text-white bg-red-600 rounded-lg min-h-[56px] w-[149px]"
                />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowWeWork;
