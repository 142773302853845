import React from "react";
import { motion } from "framer-motion";

function Footer() {
  const navItems = ["Process", "Benefits", "Services", "Portfolio", "FAQ"];

  const footerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.footer
      className="flex flex-col items-center mt-80 w-full max-w-[1166px] max-md:mt-10 max-md:max-w-full px-2 md:px-5"
      variants={footerVariants}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5 }} // Animation duration
    >
      <div className="flex flex-col md:flex-row justify-between items-start w-full">
        <div className="flex flex-col mt-3 text-white w-full md:w-1/2 text-center md:text-left">
          <h2 className="text-3xl font-bold leading-snug">
            Motion <span className="text-red-600">Hill Studio</span>
          </h2>
          <p className="mt-5 text-base leading-6">
            Motion Hill Studio - the leading digital agency based in India,
            working with top-tier clients, from start-ups to enterprises.
          </p>
        </div>

        <div className="flex flex-col items-center md:items-end text-white w-full md:w-1/2 mt-5 md:mt-0">
          <div className="flex gap-5 text-base tracking-wide whitespace-nowrap">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/30852a05319d51b3a7fa0dd6b24a7c8e717b268aa949e79517f18411e46738e7?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
              alt=""
              className="object-contain w-5 aspect-square"
            />
            <a href="mailto:Shaileshmaurya1819@gmail.com">
              Shaileshmaurya1819@gmail.com
            </a>
          </div>
          <div className="flex gap-5 mt-6 text-base tracking-wide">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/847a0031309333726efaa8a91410278a7028c22e4b8e9552066e19881179c512?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
              alt=""
              className="object-contain w-5 aspect-square"
            />
            <a href="tel:+0011231435">(+91) 9118106035</a>
          </div>
          <div className="flex gap-5 mt-7">
            <a href="www.facebook.com" aria-label="Facebook">
              <img
                loading="lazy"
                src="https://i.pinimg.com/736x/ee/6c/d5/ee6cd5e45dd45d9dccecb3b1ff6ce013.jpg"
                alt=""
                className="object-contain w-6 aspect-square"
              />
            </a>
            <a href="www.twitter.com" aria-label="Twitter">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/5d900da8ed9e2e4f3642b302e832d5e0f7b7f9d612b095bdddccd8454da84ae0?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
                alt=""
                className="object-contain w-6 aspect-square"
              />
            </a>
            <a href="www.instagram.com" aria-label="Instagram">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/25c276dd51d47c1bce7acbefd53800c8299cb2da75c27ac5b55f39dedb0d84fe?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
                alt=""
                className="object-contain w-6 aspect-square"
              />
            </a>
            <a href="www.linkedin.com" aria-label="LinkedIn">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ffe52792be95ba6cf98ed6217ba995d0c6b6ca3598164e96440dca7c2c940bb?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
                alt=""
                className="object-contain w-6 aspect-square"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="shrink-0 mt-8 max-w-full h-px border border-solid border-neutral-700 w-full" />

      <div className="flex flex-wrap gap-5 justify-between mt-7 w-full text-base text-white">
        <nav className="flex gap-8 justify-center items-center leading-loose whitespace-nowrap">
          {navItems.map((item, index) => (
            <a key={index} href={`#${item.toLowerCase()}`} className="self-stretch my-auto">
              {item}
            </a>
          ))}
        </nav>
        <p className="my-auto font-medium">
          © 2024 Motion Hill Studio - All Rights Reserved
        </p>
      </div>
    </motion.footer>
  );
}

export default Footer;
