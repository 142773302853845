import React from 'react';
import { motion } from 'framer-motion'; // Import motion for animations
import Button from './Button';

function Benefits() {
  const benefits = [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/800c4363fea933ab95480a6938665450d0cb6371dd79dbf4a881dd5b6dd1dc1b?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "1. Customized for you", description: "We design and build custom for you. We’re never starting from a template unless you want that? You don't, right?" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/75047d1e8896221763213122d191d10c51e11d225db269226f16dcf2422417bf?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "2. On-demand requests", description: "Put all your requests in the design queue in Trello, and we'll knock them out 1 by 1." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0409186ce18bb068e218eb89885dd7c0ac5575bfe59d4453e0958db46bcac63e?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "3. Top-notch quality", description: "High-end work from a dedicated team of senior designers that's always available when you need it." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/bae27c45cb36e5c31114e3a51eebd8aa85a903e459e174b9f00ac0afa41bbc5a?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "4. Powered by - Webflow", description: "We build every site on Webflow, making them dynamic, accessible, and easily scalable. It's easy for you like Squarespace but better." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f0860d41b1fd02e716b9d2fa47d173b032b3d8cdfd128e40b5aa3984bf1a9661?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "5. Fast. Responsive. Reliable.", description: "Get regular updates on your projects and can expect to receive your designs within 2-3 days." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8ef9e6000c11b8db03ccbcd8da7fe85a8fed870a169793343ac8f81afd5b446e?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "6. No Lock in contracts", description: "Pay the same every month, no surprises. You can use it for as long as you want and cancel anytime." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/08714985b1cf9a1ea2e835afd947b1c1855927ffe14f31116a383c4baec7815e?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "7. Great value for money", description: "Get the power of dedicated design team at fraction of the cost of full-time employee. ($54k/yr vs. $112k/yr)." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2d8991e9cd9155125bb3be95fd1586e37823ed856909fcfd5d2a1685fb42d498?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "8. Creative paying", description: "We're here when you need us and not on payroll when you don't." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e004af21b4a638af33d7d57479cf4ced46c0819d88a58301e007a20a9dbe54be?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2", title: "9. Expert turnovers", description: "You're getting 10+ years of design experience with every request. No hand-holding required." }
  ];

  // Define animation variants
  const titleVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5 } 
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5, delay: 0.2 } 
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5 } 
    },
  };

  const staggerContainer = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1, // Stagger each child by 0.3 seconds
      },
    },
  };

  const buttonHover = {
    scale: 1.05,
    transition: {
      duration: 0.3,
    },
  };

  return (
    <section className="flex flex-col mt-28 w-full max-w-[1192px] max-md:mt-10 max-md:max-w-full">
      <motion.h2
        className="self-start text-base font-semibold leading-snug text-red-600 uppercase"
        initial="hidden"
        whileInView="visible"
        variants={titleVariants}
        viewport={{ once: false, amount: 0.2 }} // Trigger animation when in view
      >
        BENEFITS
      </motion.h2>
      <div className="mt-4 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <motion.div
            className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
            initial="hidden"
            whileInView="visible"
            variants={titleVariants}
            viewport={{ once: false, amount: 0.2 }}
          >
            <h3 className="text-6xl font-semibold tracking-tighter text-white leading-[58px] max-md:mt-10 max-md:max-w-full max-md:text-4xl max-md:leading-10">
              The design subscription that connects you to your dream team
            </h3>
          </motion.div>
          <motion.div
            className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full"
            initial="hidden"
            whileInView="visible"
            variants={textVariants}
            viewport={{ once: false, amount: 0.2 }}
          >
            <p className="self-stretch my-auto text-2xl font-light leading-9 text-white text-opacity-80 max-md:mt-10 max-md:max-w-full">
              A subscription can alleviate the stress of staffing, managing expenses, or make design calls like a Creative Director. We partner with you to ensure that your design elevates your brand to new levels.
            </p>
            <motion.div whileHover={buttonHover}>
              <Button 
                text="See Pricing" 
                className="gap-3.5 px-3.5 py-4 mt-3 ml-0 max-w-full text-base font-medium leading-none text-white bg-red-600 rounded-lg min-h-[56px] w-[149px]"
              />
            </motion.div>
          </motion.div>
        </div>
      </div>
      
      <motion.div 
        className="grid grid-cols-1 gap-8 mt-28 md:grid-cols-3 max-md:mt-10 max-md:max-w-full"
        initial="hidden"
        whileInView="visible"
        variants={staggerContainer}
        viewport={{ once: false, amount: 0.2 }}
      >
        {benefits.map((benefit, index) => (
          <motion.div 
            key={index} 
            className="flex flex-col items-start"
            variants={cardVariants}
          >
            <img loading="lazy" src={benefit.icon} alt="" className="object-contain w-10 aspect-square" />
            <h4 className="mt-5 text-xl font-medium tracking-normal text-white">{benefit.title}</h4>
            {benefit.description && (
              <p className="self-stretch mt-3 text-base tracking-normal leading-6 text-zinc-400">
                {benefit.description}
              </p>
            )}
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
}

export default Benefits;
