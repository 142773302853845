import React from "react";

function Logo() {
  return (
    <a href="/" className="flex flex-col items-center text-center">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/5bf5ada53878067c5cb76d9d5d389e9df6aa41b9efedd6fe3311fabea52f5dc1?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
        alt="Motion Hill Studios logo"
        className="object-contain aspect-square w-[51px]"
      />
      <div className="self-stretch text-lg">MOTION HILL</div>
      <div className="-mt-1 text-[10px] font-light tracking-[3.8px]">STUDIOS</div>
    </a>
  );
}

export default Logo;
