import React from "react";
import { motion } from "framer-motion";

function Portfolio() {
  const portfolioItems = [
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/966f915d59b3a0ad09c903ffd32e2ebac7ba02e5c19f7e3b573e158e7206b0e2?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 1",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/1f421bcb3b1166a6f072fc23969291046b350c201a7f2911af218006751a5596?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 2",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bda650000c33f7c963a8f186c74ba805de3667c59a3d58d74beafdf1ac20b92c?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 3",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/afeeb1c77c95cac69d7c8fc186c7719c153e4e8128c0e016650afb3e44813f24?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 4",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/abbb0d85c090adf326d3078bea97154049abe50f34f26eae101f9c11fead01f3?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 5",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/e50da6b54b7f3d6efab6ae199294452122378ca2e4f1ff157c5f59f50a4667c8?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 6",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/7ce50ff47e917280cfcb35641cf656993230968974e1512982cf48280897c4df?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 7",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/9788a06c591cf7a919c90a9789cd52f1ec32803f5eee282347b9709e7b091d93?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 8",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/30f71612bca5ea22e37ac3e71dfb8715a13b6b976cf9ac4df0d94f47f74258be?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 9",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3cb9fdfa393f24845d1eb5cee4f46038000318e9d0112035d489963a7d7656f8?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 10",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/4eccb798b2169c2e999e9643e2b5137d1a8f2263e58c7b3df3123bb9ebeac3bd?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 11",
    },
    {
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bc377cb90176f5b8c350b0d7068c191b199eca6b76c5db3a94b176415096d565?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2",
      alt: "Portfolio item 12",
    },
  ];

    // Framer Motion animation variants
    const imageVariants = {
      hidden: { opacity: 0, y: 50 },
      visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 0.6, ease: "easeOut" },
      },
    };

    return (
      <section className="flex flex-col items-center self-stretch py-20 px-10 mt-32 w-full bg-white max-md:mt-10 max-md:px-5 max-md:max-w-full">
        <h2 className="text-5xl font-bold tracking-tighter leading-none text-center text-stone-950 max-md:text-4xl">
          Our Beautiful Works
        </h2>
        <p className="mt-4 text-lg leading-6 text-center text-stone-950 text-opacity-80 w-[462px] max-md:max-w-full">
          We help our clients grow their bottom-line with clear and professional
          websites.
        </p>
        <div className="flex items-center justify-center mt-10">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="flex items-center gap-3.5 px-7 py-5 max-w-full text-lg leading-none text-center rounded-lg border border-solid border-stone-950 text-stone-950 w-[184px] max-md:px-5"
          >
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/de6329ea306fd51ad5053e3de39adeaa1cd5074b8c9d7c4728e924979c5a0cd7?placeholderIfAbsent=true&apiKey=02dc99a1bf4e446097cb9aab7abb93c2"
              alt="loading icon"
              className="w-[25px] h-[25px] object-contain"
            />
            Load More
          </motion.button>
        </div>
        <div className="self-stretch mt-20 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            {[0, 1, 2, 3].map((columnIndex) => (
              <div
                key={columnIndex}
                className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full"
              >
                <div
                  className={`flex flex-col ${
                    columnIndex % 2 === 0 ? "grow mt-24" : ""
                  } max-md:mt-7`}
                >
                  {portfolioItems
                    .slice(columnIndex * 3, (columnIndex + 1) * 3)
                    .map((item, index) => (
                      <motion.img
                        key={index}
                        loading="lazy"
                        src={item.src}
                        alt={item.alt}
                        className={`object-contain w-full aspect-square ${index > 0 ? "mt-7" : ""}`}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: false, amount: 0.3 }} // Change `once` to false
                        variants={imageVariants}
                        whileHover={{ scale: 1.05 }}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
}

export default Portfolio;
